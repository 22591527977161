import "./App.css";

import RobotsDashBoard from "./pages/robots-dashboard";
import { Route, Switch } from "react-router-dom";
import RobotDetails from "./components/robot-details";
import { getConnect2RobotWsURL } from "./common/setting";
import DeveloperWerRTC from "./components/debugging/dev-webrtc-viewer";
import DeliveryDashBoard from "./pages/delivery-dashboard";
import WearableRobotReservationDashBoard from "./pages/wearable-robot-reservation-dashboard";
import GocartMapManagerDashboard from "./pages/gocart-map-manager";
import SpotLogViewer from "./pages/spot_log_viewer";
import GoCartTeleControlView from "./pages/robot-control-pages/gocart-tele-control-view";
import MissionDashboard from "./administrators/missions/MissionDashboard";
import SpotTeleControlView from "./pages/robot-control-pages/spot-tele-control-view";
import PageRobotCamera from "./pages/robot-camera-view";
import { Login } from "./pages/auth/Login/Login";
import { RequiresAuth } from "./components/auth/RequiresAuth";
import { ServerConnectionProvider } from "./contexts/ServerConnectionContext";
import SpotTeleControlView_NEW from "./pages/robot-control-pages/spot-tele-control-view-new";
import GoCartTeleControlView_NEW from "./pages/robot-control-pages/gocart-tele-control-view-new";
import MissionsDashboardAdmin from "./pages/missions-dashboard-admin";
import { UserProfileEditPage } from "./pages/auth/Login/UserProfileEdit";
import SysconTeleControlView from "./pages/robot-control-pages/syscon-tele-control-view";
import UserRobotsDashBoard from "./pages/robots-dashboard-user";
import { UserProvider } from "./contexts/UserDataProvider";
import { RobotMissionsProvider } from "./contexts/RobotMissionsDataProvider";
import MissionsDashboardUser from "./pages/missions-dashboard-user";
import Demobot1TeleControlView from "./demobot1/pages/demobot1-tele-control-view";
import Demobot2TeleControlView from "./demobot2/pages/demobot2-tele-control-view";
import { RobotPeerConnectionProvider } from "./contexts/RobotConnectionContext";
import SpotJobListManager from "./components/spot/spot-job-list-manager";
import DemobotControlPage from "./demobot/pages/demobot-control-page";
import { Signup } from "./pages/auth/Signup/Signup";
import Main from "./components/layout/Main";
import { MissionsDataProvider } from "./administrators/missions/contexts/RobotMissionsDataProvider";

function App() {
  console.log("App - render");

  const searchParams = new URLSearchParams(window.location.search);
  const qsHideMenuBar =
    searchParams.has("hide-menu-bar") &&
    searchParams.get("hide-menu-bar").trim().toLocaleLowerCase() == "true";

  const qsStatsEnabled = searchParams.has("show-stats")
    ? searchParams.get("show-stats").trim().toLocaleLowerCase() == "true"
    : null;
  const qsLocalMicAllowed = searchParams.has("local-mic")
    ? searchParams.get("local-mic").trim().toLocaleLowerCase() == "true"
    : null;
  const qsRemoteMicAllowed = searchParams.has("remote-mic")
    ? searchParams.get("remote-mic").trim().toLocaleLowerCase() == "true"
    : null;
  const qsRemoteCameraAllowed = searchParams.has("remote-cam")
    ? searchParams.get("remote-cam").trim().toLocaleLowerCase() == "true"
    : null;
  const qsTrickleICE = searchParams.has("trickle-ice")
    ? searchParams.get("trickle-ice").trim().toLocaleLowerCase() == "true"
    : null;

  return (
    <Switch>
      <Main>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/signup" exact>
          <Signup />
        </Route>
        <Route path="/user-profile" exact>
          <RequiresAuth>
            <UserProfileEditPage />
          </RequiresAuth>
        </Route>
        {/* <Route path="/missions-old">
          <RequiresAuth requiredRoles={["log_manager"]}>
            <MissionsDashboard></MissionsDashboard>
          </RequiresAuth>
        </Route> */}

        <Route path="/missions" exact>
          <RequiresAuth requiredRoles={["log_manager"]}>
            <MissionsDataProvider>
              <MissionDashboard></MissionDashboard>
            </MissionsDataProvider>
          </RequiresAuth>
        </Route>

        <Route path="/missions-old" exact>
          <RequiresAuth requiredRoles={["log_manager"]}>
            <RobotMissionsProvider>
              <MissionsDashboardUser></MissionsDashboardUser>
            </RobotMissionsProvider>
          </RequiresAuth>
        </Route>

        <Route path="/missions-admin">
          <RequiresAuth requiredRoles={["log_manager"]}>
            <MissionsDashboardAdmin></MissionsDashboardAdmin>
          </RequiresAuth>
        </Route>
        <Route path="/wearable-reservation">
          <RequiresAuth>
            <WearableRobotReservationDashBoard></WearableRobotReservationDashBoard>
          </RequiresAuth>
        </Route>
        <Route path="/map-manager">
          <RequiresAuth requiredRoles={["admin"]}>
            <GocartMapManagerDashboard></GocartMapManagerDashboard>
          </RequiresAuth>
        </Route>

        <Route path="/spot-log">
          <RequiresAuth requiredRoles={["log_manager"]}>
            <SpotLogViewer></SpotLogViewer>
          </RequiresAuth>
        </Route>

        <Route path="/delivery">
          <DeliveryDashBoard></DeliveryDashBoard>
        </Route>
        <Route path="/spot-jobs-manager">
          <RequiresAuth requiredRoles={["operator", "admin"]}>
            <SpotJobListManager></SpotJobListManager>
          </RequiresAuth>
        </Route>

        <ServerConnectionProvider wsUrl={getConnect2RobotWsURL()}>
          <Route path="/robots/:id/gocart" exact>
            <RequiresAuth requiredRoles={["operator"]}>
              <RobotPeerConnectionProvider
                StatsEnabled={true}
                dataChannels={["chat"]}
              >
                <GoCartTeleControlView></GoCartTeleControlView>
              </RobotPeerConnectionProvider>
            </RequiresAuth>
          </Route>
          <Route path="/robots/:id/gocart-new">
            <RequiresAuth requiredRoles={["operator"]}>
              <RobotPeerConnectionProvider
                StatsEnabled={true}
                dataChannels={["chat"]}
              >
                <GoCartTeleControlView_NEW></GoCartTeleControlView_NEW>
              </RobotPeerConnectionProvider>
            </RequiresAuth>
          </Route>
          <Route path="/robots/:id/syscon">
            <RequiresAuth requiredRoles={["operator"]}>
              <RobotPeerConnectionProvider
                StatsEnabled={true}
                dataChannels={["chat"]}
              >
                <SysconTeleControlView></SysconTeleControlView>
              </RobotPeerConnectionProvider>
            </RequiresAuth>
          </Route>
          <Route path="/robots/:id/spot-old">
            <RequiresAuth requiredRoles={["operator"]}>
              <RobotPeerConnectionProvider
                StatsEnabled={true}
                dataChannels={["chat"]}
              >
                <SpotTeleControlView></SpotTeleControlView>
              </RobotPeerConnectionProvider>
            </RequiresAuth>
          </Route>
          <Route path="/robots/:id/spot">
            <RequiresAuth requiredRoles={["operator"]}>
              <RobotPeerConnectionProvider
                StatsEnabled={qsStatsEnabled ?? true}
                allowLocalAudio={qsLocalMicAllowed ?? true}
                allowRemoteAudio={qsRemoteMicAllowed ?? true}
                isReceiveAudio={qsRemoteMicAllowed ?? true}
                isSendingAudio={qsLocalMicAllowed ?? true}
                trickleICE={qsTrickleICE ?? true}
                disableChannelMessageState={true}
                dataChannels={["chat"]}
              >
                <SpotTeleControlView_NEW></SpotTeleControlView_NEW>
              </RobotPeerConnectionProvider>
            </RequiresAuth>
          </Route>
          <Route path="/robots/:id/tracer">
            <RequiresAuth requiredRoles={["operator"]}>
              <RobotPeerConnectionProvider
                StatsEnabled={qsStatsEnabled ?? true}
                allowLocalAudio={qsLocalMicAllowed ?? true}
                allowRemoteAudio={qsRemoteMicAllowed ?? true}
                isReceiveAudio={qsRemoteMicAllowed ?? true}
                isSendingAudio={qsLocalMicAllowed ?? true}
                trickleICE={qsTrickleICE ?? true}
                disableChannelMessageState={true}
                dataChannels={["chat"]}
              >
                <Demobot1TeleControlView></Demobot1TeleControlView>
              </RobotPeerConnectionProvider>
            </RequiresAuth>
          </Route>
          <Route path="/robots/:id/demobot2">
            <RequiresAuth requiredRoles={["operator"]}>
              <RobotPeerConnectionProvider
                StatsEnabled={qsStatsEnabled ?? true}
                allowLocalAudio={qsLocalMicAllowed ?? true}
                allowRemoteAudio={qsRemoteMicAllowed ?? true}
                isReceiveAudio={qsRemoteMicAllowed ?? true}
                isSendingAudio={qsLocalMicAllowed ?? true}
                trickleICE={qsTrickleICE ?? true}
                disableChannelMessageState={true}
                dataChannels={["chat"]}
              >
                <Demobot2TeleControlView></Demobot2TeleControlView>
              </RobotPeerConnectionProvider>
            </RequiresAuth>
          </Route>
          <Route path="/remote-control/:id">
            <RequiresAuth requiredRoles={["operator"]}>
              <RobotPeerConnectionProvider
                StatsEnabled={qsStatsEnabled ?? true}
                allowLocalAudio={qsLocalMicAllowed ?? true}
                allowRemoteAudio={qsRemoteMicAllowed ?? true}
                isReceiveAudio={qsRemoteMicAllowed ?? true}
                isSendingAudio={qsLocalMicAllowed ?? true}
                // trickleICE={qsTrickleICE ?? true}
                // disableChannelMessageState={true}
                dataChannels={["chat"]}
              >
                <DemobotControlPage></DemobotControlPage>
              </RobotPeerConnectionProvider>
            </RequiresAuth>
          </Route>
          <Route path="/robot-control/:id">
            <RequiresAuth requiredRoles={["operator"]}>
              <RobotPeerConnectionProvider
                StatsEnabled={qsStatsEnabled ?? true}
                allowLocalAudio={qsLocalMicAllowed ?? true}
                allowRemoteAudio={qsRemoteMicAllowed ?? true}
                isReceiveAudio={qsRemoteMicAllowed ?? true}
                isSendingAudio={qsLocalMicAllowed ?? true}
                // trickleICE={qsTrickleICE ?? true}
                // disableChannelMessageState={true}
                dataChannels={["chat"]}
              >
                <DemobotControlPage></DemobotControlPage>
              </RobotPeerConnectionProvider>
            </RequiresAuth>
          </Route>
          <Route path="/robots/:id">
            <RobotDetails></RobotDetails>
          </Route>
          {/* <Route path="/dashboard2">
                    <RobotsDashBoard></RobotsDashBoard>
                  </Route> */}
          <Route path="/conn-dev/:id">
            <RobotPeerConnectionProvider
              StatsEnabled={qsStatsEnabled ?? true}
              allowLocalAudio={qsLocalMicAllowed ?? true}
              allowRemoteAudio={qsRemoteMicAllowed ?? true}
              isReceiveAudio={qsRemoteMicAllowed ?? true}
              isSendingAudio={qsLocalMicAllowed ?? true}
              trickleICE={qsTrickleICE ?? true}
              // disableChannelMessageState={true}
              dataChannels={["chat"]}
            >
              <DeveloperWerRTC></DeveloperWerRTC>
            </RobotPeerConnectionProvider>
          </Route>
          {/* <Route path="/delivery">
                    <RequiresAuth requiredRoles={["log_manager"]}>
                      <DeliveryDashBoard></DeliveryDashBoard>
                    </RequiresAuth>
                  </Route> */}

          <Route path="/robot-camera/:id">
            <RobotPeerConnectionProvider
              StatsEnabled={qsStatsEnabled ?? true}
              allowLocalAudio={qsLocalMicAllowed ?? true}
              allowRemoteAudio={qsRemoteMicAllowed ?? true}
              isReceiveAudio={qsRemoteMicAllowed ?? true}
              isSendingAudio={qsLocalMicAllowed ?? true}
              trickleICE={qsTrickleICE ?? true}
              disableChannelMessageState={true}
              dataChannels={["chat"]}
            >
              <PageRobotCamera></PageRobotCamera>
            </RobotPeerConnectionProvider>
          </Route>

          <Route path="/home">
            <RobotsDashBoard></RobotsDashBoard>
          </Route>
          <Route path="/dashboard">
            <UserProvider>
              <UserRobotsDashBoard></UserRobotsDashBoard>
            </UserProvider>
          </Route>
          <Route path="/" exact>
            <UserProvider>
              <UserRobotsDashBoard></UserRobotsDashBoard>
            </UserProvider>
          </Route>
          {/* <Redirect from="*" to="/dashboard" /> */}
        </ServerConnectionProvider>
      </Main>
    </Switch>
  );
}

export default App;
