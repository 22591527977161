import React, { useEffect, useState } from "react";
import { useMissionsData } from "../contexts/RobotMissionsDataProvider";
import {
  getTimeBlockKeysToIndex,
  getTimeBlockKey,
} from "../../../utils/datetime";
import EBarChart from "../../components/chart/EBarChart";
import { getMissionCreateTime, getMissionStatus } from "../mission_utils";
import { Card, Col, Row, Typography, Radio, Spin } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

const BarChartTotalMissionsByTime = () => {
  const { Title, Text } = Typography;

  const {
    robotMissionsDataState,
    error,
    dataLoading: isPending,
    getMisionStatusSeriesDataBarChart,
  } = useMissionsData();

  const missions = robotMissionsDataState.missionsList;

  const [missionsBarTimeOption, setMissionsBarTimeOption] = useState("week");
  const [missionTimeCategories, setMissionTimeCategories] = useState([]);
  const [missionsBarSeriesData, setMissionsBarSeriesData] = useState([]);
  const [missionsLineSeriesData, setMissionsLineSeriesData] = useState([]);

  const getMisionStatusBarChartData = (statusNames, timePeriod = "MONTH") => {
    if (missions == null) {
      return [], [];
    }

    let minDate = new Date(robotMissionsDataState.firstDate);
    let maxDate = new Date(robotMissionsDataState.lastDate);

    const seriesDataMap = getMisionStatusSeriesDataBarChart(
      getMissionStatus,
      (mission) => getTimeBlockKey(getMissionCreateTime(mission), timePeriod)
    );

    const seriesDataIndexMap = getTimeBlockKeysToIndex(
      minDate,
      maxDate,
      timePeriod
    );

    const dataLength = Object.keys(seriesDataIndexMap).length;
    const seriesDataIndex2Key = Array(dataLength).fill(0);
    for (const [key, index] of Object.entries(seriesDataIndexMap)) {
      seriesDataIndex2Key[index] = key;
    }

    const series = [];
    const lineSeries = [];
    const totalData = Array(dataLength).fill(0);
    const totalAccumulativeData = Array(dataLength).fill(0);
    for (const status of statusNames) {
      const data = Array(dataLength).fill(0);
      const linesData = Array(dataLength).fill(0);

      let sum = 0;
      if (status in seriesDataMap) {
        for (let index = 0; index < seriesDataIndex2Key.length; index++) {
          const timeKey = seriesDataIndex2Key[index];
          const value = seriesDataMap[status][timeKey] ?? 0;
          const indexBar = seriesDataIndexMap[timeKey];
          data[indexBar] = value;
          totalData[indexBar] += value;

          sum += value;
          linesData[index] = sum;
        }
      }

      series.push({
        name: status,
        type: "bar",
        stack: "total",
        label: {
          show: false, //timePeriod.toLowerCase() !== "day",
        },
        emphasis: {
          focus: "series",
        },
        data: data,
      });

      lineSeries.push({
        name: status,
        type: "line",
        // stack: "total",
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        data: linesData,
      });
    }
    if (dataLength > 0) {
      totalAccumulativeData[0] = totalData[0];
      for (let index = 1; index < dataLength; index++) {
        totalAccumulativeData[index] =
          totalAccumulativeData[index - 1] + totalData[index];
      }
    }

    const categories = Object.keys(seriesDataIndexMap);

    series.push({
      name: "Total",
      type: "line",
      label: {
        show: true,
      },
      emphasis: {
        focus: "series",
      },
      data: totalData,
    });

    lineSeries.push({
      name: "Total",
      type: "line",
      label: {
        show: true,
      },
      emphasis: {
        focus: "series",
      },
      data: totalAccumulativeData,
    });

    setMissionTimeCategories(categories);
    setMissionsBarSeriesData(series);

    setMissionsLineSeriesData(lineSeries);
    return [categories, series];
  };

  useEffect(() => {
    if (missions == null) return;

    const statusNames = Object.keys(robotMissionsDataState.missionStatusCounts);
    const [categories, series] = getMisionStatusBarChartData(
      statusNames,
      missionsBarTimeOption
    );
  }, [missions, missionsBarTimeOption]);

  const onChange = (e) => {
    const timeBlockOption = e.target.value;
    setMissionsBarTimeOption(timeBlockOption);
    const statusNames = Object.keys(robotMissionsDataState.missionStatusCounts);
    getMisionStatusBarChartData(statusNames, timeBlockOption);
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
          <Card variant="borderless" className="criclebox h-full">
            <div className="project-ant">
              <div>
                <Title level={5}>Total missions</Title>
                <Paragraph className="lastweek">
                  grouped by{" "}
                  <span className="blue">
                    {missionsBarTimeOption.toUpperCase()}
                  </span>
                </Paragraph>
              </div>
              <div className="ant-filtertabs">
                <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                  <Radio.Group
                    onChange={onChange}
                    defaultValue={missionsBarTimeOption}
                    value={missionsBarTimeOption}
                  >
                    <Radio.Button value="day">DAY</Radio.Button>
                    <Radio.Button value="week">WEEK</Radio.Button>
                    <Radio.Button value="month">MONTH</Radio.Button>
                    <Radio.Button value="year">YEAR</Radio.Button>
                  </Radio.Group>
                </div>
              </div>
            </div>
            <div className="ant-list-box">
              <Card variant="borderless" className="criclebox h-full">
                <Spin spinning={isPending}>
                  <EBarChart
                    // title={{ text: "Total missions" }}
                    categories={missionTimeCategories}
                    series={missionsBarSeriesData}
                    legend={{ left: "left", orient: "horizontal" }}
                  ></EBarChart>
                </Spin>
              </Card>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
          <Card variant="borderless" className="criclebox h-full">
            <div className="timeline-box">
              <Title level={5}>Total missions accumulative</Title>
              {/* <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                mission name ABC <span className="bnb2">20%</span>
              </Paragraph> */}
              {/* <div className="ant-filtertabs">
                <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                  <Radio.Group
                    onChange={onChange}
                    defaultValue={missionsBarTimeOption}
                    value={missionsBarTimeOption}
                  >
                    <Radio.Button value="day">DAY</Radio.Button>
                    <Radio.Button value="week">WEEK</Radio.Button>
                    <Radio.Button value="month">MONTH</Radio.Button>
                    <Radio.Button value="year">YEAR</Radio.Button>
                  </Radio.Group>
                </div>
              </div> */}
              <div className="ant-list-box">
                <Card variant="borderless" className="criclebox h-full">
                  <Spin spinning={isPending}>
                    <EBarChart
                      // title={{ text: "Total missions accumulative" }}
                      categories={missionTimeCategories}
                      series={missionsLineSeriesData}
                    ></EBarChart>
                  </Spin>
                </Card>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BarChartTotalMissionsByTime;
