export const getMissionStatus = (mission) => {
  if (mission == null) {
    return null;
  }
  return mission.status ?? mission.mission_status ?? "N/A";
};

export const getMissionCreateTime = (mission) => {
  if (mission == null) {
    return null;
  }

  let create_time = mission.create_time;
  if (create_time instanceof String || typeof create_time == "string") {
    create_time = new Date(create_time);
  }

  if (!(create_time instanceof Date)) {
    console.log("time not a date: ", create_time);
  }

  return create_time;
};
