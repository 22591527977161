import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useAuth } from "../../../contexts/AuthProvider";
import { useLocation, useHistory } from "react-router-dom";
import {
  initialMissionsData,
  robotMissionsDataReducer,
} from "../reducers/robotMissionsDataReducer";
import { getUserRobotMissionListService } from "../../../services/mission-services";
import React from "react";
import { getMissionCreateTime, getMissionStatus } from "../mission_utils";
import { getTimeBlockKey } from "../../../utils/datetime";

const RobotMissionsDataContext = createContext();

export function MissionsDataProvider({ children }) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState("");
  const [robotMissionsDataState, dispatch] = useReducer(
    robotMissionsDataReducer,
    initialMissionsData
  );

  const { auth, redirectToLoginPage } = useAuth();

  const history = useHistory();
  const location = useLocation();

  const getRobotMissionList = async () => {
    if (auth.isAuth) {
      try {
        setDataLoading(true);
        const response = await getUserRobotMissionListService(auth.token);
        dispatch({ type: "SET_MISSIONLIST", payload: response.data });
      } catch (error) {
        console.error(error);
      } finally {
        setDataLoading(false);
      }
    } else {
      redirectToLoginPage(location);
    }
  };

  const getMisionStatusSeriesDataBarChart = (
    categoriesKeyFunc = getMissionStatus,
    seriesKeyFunc = (mission) =>
      getTimeBlockKey(getMissionCreateTime(mission), "month")
  ) => {
    const missions = robotMissionsDataState.missionsList;
    if (missions == null) {
      return [], [];
    }

    const seriesDataMap = {};

    for (const mission of missions) {
      const categoryKey = categoriesKeyFunc(mission);
      const seriesItemKey = seriesKeyFunc(mission);

      if (!(categoryKey in seriesDataMap)) {
        seriesDataMap[categoryKey] = {};
      }

      let seriesData = seriesDataMap[categoryKey];
      seriesData[seriesItemKey] = (seriesData[seriesItemKey] ?? 0) + 1;
    }
    console.log("Categories Map: ", seriesDataMap);
    return seriesDataMap;
  };

  useEffect(() => {
    getRobotMissionList();
  }, [auth]);

  return (
    <RobotMissionsDataContext.Provider
      value={{
        robotMissionsDataState,
        error,
        dispatch,
        dataLoading,
        getRobotMissionList,
        getMisionStatusSeriesDataBarChart,
      }}
    >
      {children}
    </RobotMissionsDataContext.Provider>
  );
}

export const useMissionsData = () => useContext(RobotMissionsDataContext);
