import { Card, Col, Row, Typography, Spin } from "antd";
import EPieChart from "../components/chart/EPieChart";
import BarChartTotalMissionsByTime from "./components/BarChartTotalMissionsByTime";
import { useMissionsData } from "./contexts/RobotMissionsDataProvider";
import MissionsList from "./components/MissionsList";

function MissionDashboard() {
  const { Title, Text } = Typography;

  const {
    robotMissionsDataState,
    error,
    dataLoading: isPending,
  } = useMissionsData();

  const missionStatusCounts = Object.entries(
    robotMissionsDataState.missionStatusCounts
  ).map(([status, value]) => ({ name: status, value }));

  const missionTypesCounts = Object.entries(
    robotMissionsDataState.missionTypeCounts
  ).map(([status, value]) => ({ name: status, value }));

  const missionRobotsCounts = Object.entries(
    robotMissionsDataState.missionRobotCounts
  ).map(([status, value]) => ({ name: status, value }));

  const countsData = [
    {
      title: "Mission Status",
      data: missionStatusCounts,
    },
    {
      title: "Mission Types",
      data: missionTypesCounts,
    },
    {
      title: "Robot's Missions",
      data: missionRobotsCounts,
    },
  ];

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {countsData.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              className="mb-24"
            >
              <Card variant="borderless" className="criclebox h-full">
                <Spin spinning={isPending}>
                  <EPieChart data={c.data} title={c.title} />
                </Spin>
              </Card>
            </Col>
          ))}
        </Row>

        <BarChartTotalMissionsByTime></BarChartTotalMissionsByTime>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} lassName="mb-24">
            <Card variant="borderless" className="criclebox cardbody h-full">
              <div className="project-ant">
                <div>
                  <Title level={5}>Missions list</Title>
                </div>
              </div>
              <Card variant="borderless" className="criclebox h-full">
                <MissionsList></MissionsList>
              </Card>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default MissionDashboard;
