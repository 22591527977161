export function iterateYears(startDate, endDate, callback) {
  let currentDate = new Date(startDate.getFullYear(), 0, 1);
  const end = new Date(endDate.getFullYear(), 0, 1);

  while (currentDate <= end) {
    callback(new Date(currentDate)); // Pass a copy to avoid mutation
    currentDate.setFullYear(currentDate.getFullYear() + 1);
  }
}

// // Example usage:
// const start = new Date(2020, 0, 1); // January 1, 2020
// const end = new Date(2025, 0, 1);   // January 1, 2025

// iterateYears(start, end, (date) => {
//     console.log(date.toISOString().slice(0, 4)); // Logs YYYY
// });

// =================================================================================================
export function iterateMonths(startDate, endDate, callback) {
  let currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
  const end = new Date(endDate.getFullYear(), endDate.getMonth(), 1);

  while (currentDate <= end) {
    callback(new Date(currentDate)); // Pass a copy to avoid mutation
    currentDate.setMonth(currentDate.getMonth() + 1);
  }
}

// // Example usage:
// // const start = new Date(2023, 0, 1); // January 1, 2023
// const end = new Date(2023, 5, 1);   // June 1, 2023

// iterateMonths(start, end, (date) => {
//     console.log(date.toISOString().slice(0, 7)); // Logs YYYY-MM
// }

// =================================================================================================
export function iterateWeeks(startDate, endDate, callback) {
  let currentDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );
  const end = new Date(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate()
  );

  while (currentDate <= end) {
    callback(new Date(currentDate)); // Pass a copy to avoid mutation
    currentDate.setDate(currentDate.getDate() + 7);
  }
}

// // Example usage:
// const start = new Date(2023, 0, 1); // January 1, 2023
// const end = new Date(2023, 1, 1);   // February 1, 2023

// iterateWeeks(start, end, (date) => {
//     console.log(date.toISOString().slice(0, 10)); // Logs YYYY-MM-DD
// });

// iterateDays(start, end, (date) => {
//     console.log(date.toISOString().slice(0, 10), date.toLocaleString('en-US', { weekday: 'long' })); // Logs YYYY-MM-DD and week name
// });

// =================================================================================================
export function iterateDays(startDate, endDate, callback) {
  let currentDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );
  const end = new Date(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate()
  );

  while (currentDate <= end) {
    callback(new Date(currentDate)); // Pass a copy to avoid mutation
    currentDate.setDate(currentDate.getDate() + 1);
  }
}

// // Example usage:
// const start = new Date(2023, 0, 1); // January 1, 2023
// const end = new Date(2023, 0, 10);   // January 10, 2023

// iterateDays(start, end, (date) => {
//     console.log(date.toISOString().slice(0, 10)); // Logs YYYY-MM-DD
// });

// =================================================================================================
export const getTimeBlockKey = (time, period = "MONTH") => {
  if (time == null) {
    return "N/A";
  }
  if (typeof time === "string") {
    time = new Date(time);
  }

  if (!(time instanceof Date)) {
    return time + "";
  }

  switch (period.toLowerCase()) {
    case "year":
      return "" + time.getFullYear();
    case "month":
      return time.getFullYear() + "-" + time.getMonth();
    case "week":
      //get the date of the last monday from the "time" date
      const weekDay = time.getDay();
      const monday = new Date(time);
      monday.setDate(time.getDate() - weekDay);
      return (
        monday.getFullYear() + "-" + monday.getMonth() + "-w" + monday.getDate()
      );
    case "day":
      return time.getFullYear() + "-" + time.getMonth() + "-" + time.getDate();
    default:
      return null;
  }
};

// =================================================================================================
export const getTimeBlockKeysToIndex = (
  minDate,
  maxDate,
  timePeriod = "month"
) => {
  const timeBlocksMap = {};
  let count = 0;
  switch (timePeriod) {
    case "year":
      iterateYears(minDate, maxDate, (date) => {
        timeBlocksMap[getTimeBlockKey(date, timePeriod)] = count++;
      });
      break;
    case "month":
      iterateMonths(minDate, maxDate, (date) => {
        timeBlocksMap[getTimeBlockKey(date, timePeriod)] = count++;
      });
      break;
    case "week":
      iterateWeeks(minDate, maxDate, (date) => {
        timeBlocksMap[getTimeBlockKey(date, timePeriod)] = count++;
      });
      break;
    case "day":
      iterateDays(minDate, maxDate, (date) => {
        timeBlocksMap[getTimeBlockKey(date, timePeriod)] = count++;
      });
      break;
    default:
      break;
  }
  return timeBlocksMap;
};
