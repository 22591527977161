import "../../App.css";
import { useState } from "react";

import MenuBar from "../menubar";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";

import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";

function Main({ children }) {
  const searchParams = new URLSearchParams(window.location.search);
  const qsHideMenuBar =
    searchParams.has("hide-menu-bar") &&
    searchParams.get("hide-menu-bar").trim().toLocaleLowerCase() == "true";

  const [collapsed, setCollapsed] = useState(false);

  const zeroWidthTriggerStyle = {
    top: 35,
    right: collapsed ? -15 : 0,
    width: 24,
    height: 24,
    // borderRadius: "50%",
    transition: "all 0.3s",
    // background: "#001529",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    cursor: "pointer",
  };

  return (
    <div>
      <Layout>
        {!qsHideMenuBar && (
          <Sider
            theme="dark"
            trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            zeroWidthTriggerStyle={zeroWidthTriggerStyle}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            breakpoint="lg"
            collapsedWidth="0"
          >
            <div
              style={{
                height: 32,
                margin: 16,
                // background: "rgba(255, 255, 255, 0.2)",
              }}
            >
              {!collapsed && (
                <Link
                  className="navbar-brand px-0 fs-6"
                  style={{
                    // height: 32,
                    margin: 6,
                    color: "rgba(255, 255, 255, 0.9)",
                    background: "rgba(255, 255, 255, 0.2)",
                  }}
                  to="/"
                >
                  KETI RUBBERNECK
                </Link>
              )}
            </div>
            <MenuBar />
          </Sider>
        )}

        <Layout>
          {/* <Header theme="dark">
            <NavBar />
          </Header> */}
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default Main;
