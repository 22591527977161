import { getMissionCreateTime, getMissionStatus } from "../mission_utils";

export const robotMissionsDataReducer = (state, action) => {
  switch (action.type) {
    case "SET_MISSIONLIST": {
      const missions = action.payload;
      let minDate = new Date();
      let maxDate = new Date(0); // set to the earliest date possible

      const missionStatusCounts = {};

      const missionRobotCounts = {};
      const missionTypeCounts = {};

      for (const mission of missions) {
        const mission_status = getMissionStatus(mission);
        missionStatusCounts[mission_status] =
          (missionStatusCounts[mission_status] ?? 0) + 1;

        missionRobotCounts[mission.robot_id] =
          (missionRobotCounts[mission.robot_id] ?? 0) + 1;
        missionTypeCounts[mission.mission_name] =
          (missionTypeCounts[mission.mission_name] ?? 0) + 1;

        let time = getMissionCreateTime(mission);
        if (time != null) {
          if (time < minDate) {
            minDate = time;
          }
          if (time > maxDate) {
            maxDate = time;
          }
        }
      }
      return {
        ...state,
        missionsList: missions,
        firstDate: minDate,
        lastDate: maxDate,
        missionRobotCounts: missionRobotCounts,
        missionStatusCounts: missionStatusCounts,
        missionTypeCounts: missionTypeCounts,
      };
    }

    default:
      return state;
  }
};

export const initialMissionsData = {
  missionsList: [],
  firstDate: new Date(),
  lastDate: new Date(),
  missionRobotCounts: {},
  missionStatusCounts: {},
  missionTypeCounts: {},
};
